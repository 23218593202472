<template>
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.84681 2C3.173 2 3.46954 2.26367 3.52885 2.58594L3.58816 2.9375H16.0429C16.6657 2.9375 17.1401 3.55273 16.9622 4.13867L15.3609 9.76367C15.2423 10.1738 14.8864 10.4375 14.4416 10.4375H5.04122L5.30811 11.8438H14.4713C14.8568 11.8438 15.183 12.166 15.183 12.5469C15.183 12.957 14.8568 13.25 14.4713 13.25H4.71502C4.38882 13.25 4.09228 13.0156 4.03297 12.6934L2.25372 3.40625H0.711701C0.296542 3.40625 0 3.11328 0 2.70312C0 2.32227 0.296542 2 0.711701 2H2.84681ZM3.79574 15.5938C3.79574 14.832 4.41848 14.1875 5.21914 14.1875C5.99015 14.1875 6.64255 14.832 6.64255 15.5938C6.64255 16.3848 5.99015 17 5.21914 17C4.41848 17 3.79574 16.3848 3.79574 15.5938ZM15.183 15.5938C15.183 16.3848 14.5306 17 13.7596 17C12.9589 17 12.3362 16.3848 12.3362 15.5938C12.3362 14.832 12.9589 14.1875 13.7596 14.1875C14.5306 14.1875 15.183 14.832 15.183 15.5938Z"
      :fill="props.fill"
    />
    <circle cx="15" cy="4" r="4" fill="#F58025" />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    required: false,
    default: 'white',
  },
});
</script>
