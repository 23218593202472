/**
 * imports
 */

import 'vite/modulepreload-polyfill';
import {
  createApp,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  reactive,
} from 'vue';

import piniaStore from '../plugins/piniaStore';
import { useGrid } from 'vue-screen';
import InstantSearch from '../components/render/InstantSearch.vue';
import Basket from '../components/render/Basket.vue';
import TransitionExpand from '../components/render/transitions/TransitionExpand.vue';

/**
 * create vue instance function
 */
const createVueApp = () => {
  const app = createApp({
    setup() {
      defineComponent({
        components: {},
      });

      app.use(piniaStore);

      const nav = reactive({
        showAnnouncement: true,
        showNavbar: true,
        showSticky: false,
        showMobileSearch: true,
        lastScrollPosition: 0,
      });

      const data = reactive({
        mounted: false,
        showMenu: false,
        activeLinkTitle: '',
        secondMenuIdx: null,
        thirdMenuIdx: null,
        metaObject: null,
      });

      const grid = useGrid({
        mobile: '416px',
        tablet: '640px',
        'tablet-lg': '1024px',
        laptop: '1152px',
        desktop: '1460px',
        ultrawide: '2000px',
      });

      const toggleMenu = () => {
        data.showMenu = !data.showMenu;
        if (grid.desktop) {
          data.secondMenuIdx = 1;
          data.thirdMenuIdx = 11;
        } else {
          data.secondMenuIdx = null;
          data.thirdMenuIdx = null;
          data.showMenu
          ? document.body.classList.add('hide__scroll', 'hide__scroll--fixed')
          : document.body.classList.remove('hide__scroll', 'hide__scroll--fixed')
        }
      };

      const toggleSecondMenu = (secondMenuKey) => {
        if (data.secondMenuIdx !== secondMenuKey) {
          data.secondMenuIdx = secondMenuKey;
        } else {
          data.secondMenuIdx = null;
        }
        data.thirdMenuIdx = null;
        data.activeLinkTitle = '';
      };

      const toggleThirdMenu = (thirdMenuKey, secondMenuLink) => {
        if (data.thirdMenuIdx !== thirdMenuKey) {
          data.thirdMenuIdx = thirdMenuKey;
        } else {
          data.thirdMenuIdx = null;
        }
        data.activeLinkTitle = secondMenuLink;
      };

      const onScroll = () => {
        const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollPosition < 0) {
          return;
        }
        if (Math.abs(currentScrollPosition - nav.lastScrollPosition) <= 40) {
          return;
        }
        nav.showAnnouncement = currentScrollPosition <= 425;
        nav.showSticky = currentScrollPosition >= 100;
        nav.showMobileSearch = currentScrollPosition <= 100;
        nav.lastScrollPosition = currentScrollPosition;
      };

      onMounted(() => {
        window.addEventListener('scroll', onScroll);

        document.querySelector('main').addEventListener('click', () => {
          data.showMenu = false;
        });
        data.mounted = true
      });

      onBeforeUnmount(() => {
        window.removeEventListener('scroll', onScroll);
      });

      return {
        nav,
        data,
        toggleMenu,
        toggleSecondMenu,
        toggleThirdMenu,
      };
    },
  });

  return app;
};

/**
 * create and mount vue instance(s)
 */
// import { pinia } from './main'
const header = document.querySelector('.vue__header');

if (header) {
  createVueApp({})
    .component('InstantSearch', InstantSearch)
    .component('Basket', Basket)
    .component('TransitionExpand', TransitionExpand)
    .mount(header);
}

/**
 * fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */
if (window.Shopify.designMode) {
  document.addEventListener('shopify:section:load', (event) => {
    if (event.target.classList.value.includes('vue')) {
      createVueApp().mount(event.target);
    }
  });
} else if (
  !window.Shopify.designMode &&
  process.env.NODE_ENV === 'development'
) {
  new window.MutationObserver((mutationsList) => {
    mutationsList.forEach((record) => {
      const vue = Array.from(record.addedNodes).find(
        (node) => node.classList && node.classList.value.includes('vue')
      );
      if (vue) window.location.reload();
    });
  }).observe(document.body, {
    childList: true,
    subtree: true,
  });
}
