<template>
  <div class="basket">
    <template v-if="!activeCart">
    <icon-cart :fill="props.fill" />
    <p class="accent">Cart</p>
  </template>
  <template v-else>
    <icon-cart-active :fill="props.fill" />
    <p class="accent">Cart</p>
  </template>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '../../stores/cart';
import IconCart from './icons/IconCart.vue';
import IconCartActive from './icons/IconCartActive.vue';

const props = defineProps({
  fill: {
    type: String,
    required: false,
    default: () => '#000000',
  },
  customer: {
    type: Object,
    required: true,
  }
});

const useCart = useCartStore();

const activeCart = computed(() => {
  return useCart.cartItems > 0 ? true : false;
});

onMounted(() => {
  useCart.loadCartItems()
});
</script>
